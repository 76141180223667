const insertOrganizationIdToUrl = (url, organizationId) => {
  if (url.includes('v1.1'))
    url = organizationId
      ? url.replace('v1.1/', 'v1.1/' + organizationId + '/')
      : url
  if (url.includes('v1'))
    url = organizationId
      ? url.replace('v1/', 'v1/' + organizationId + '/')
      : url
  return url
}

export default insertOrganizationIdToUrl
