import _ from 'lodash-core'
import moment from 'moment'

/**
 * Adjust conversation structure
 * @param {Array} conversations Conversation data
 * @param {String} channel Channel slug i.e email, ig, wa, etc
 * @param {Array} participants List of room participants
 * @returns {Array} Formatted message
 */
export const conversationFormatter = (
  conversations,
  channel,
  participants = [],
  isLoadMore = false
) => {
  const message = []
  conversations.forEach((item) => {
    let filetype = []
    let attachments = []

    if (item.file) {
      attachments.push(item.file ? item.file : [])
    } else if (item.attachments) {
      const fileList = []

      item.attachments.forEach((data) => {
        fileList.push(data.file)
      })
      attachments = fileList
    }

    if (
      item.type === 'document' ||
      item.type === 'image' ||
      item.type === 'video'
    ) {
      filetype = item.file?.filename?.split('.') ?? ''
    }

    // get sender from participant list
    let senderProfile = {
      name: item.sender?.name || 'User',
      avatar: item.sender?.avatar ? item.sender?.avatar?.large?.url : null,
    }
    const senderFromParticipant = participants.find(
      (data) => data.id === item.participant_id
    )
    if (senderFromParticipant) {
      senderProfile = {
        ...senderFromParticipant.profile,
        avatar: senderFromParticipant.profile?.avatar?.large?.url,
      }
    }

    const messageItem = {
      ...item,
      id: item.id,
      message: {
        type: item.type,
        text: item.text ? item.text : null,
        image: {
          large: item.type === 'image' ? item.file.url : null,
          medium: item.type === 'image' ? item.file.large.url : null,
          small: item.type === 'image' ? item.file.large.url : null,
          filename: item.type === 'image' ? item.file.filename : null,
          filetype:
            item.type === 'image' ? filetype[filetype.length - 1] : null,
          size:
            item.type === 'image'
              ? (item.file.size / 1048576).toFixed(2)
              : null,
        },
        document: {
          url: item.type === 'document' ? item.file.url : null,
          filename: item.type === 'document' ? item.file.filename : null,
          filetype:
            item.type === 'document' ? filetype[filetype.length - 1] : null,
          size:
            item.type === 'document'
              ? (item.file.size / 1048576).toFixed(2)
              : null,
        },
        video: {
          large: item.type === 'video' ? item.file.url : null,
          medium: item.type === 'video' ? item.file.large.url : null,
          small: item.type === 'video' ? item.file.large.url : null,
          filename: item.type === 'video' ? item.file.filename : null,
          filetype:
            item.type === 'video' ? filetype[filetype.length - 1] : null,
          size:
            item.type === 'video'
              ? (item.file.size / 1048576).toFixed(2)
              : null,
          url: item.type === 'video' ? item.file.url : null,
        },
        location: {
          url: item.extra ? item.extra.url : null,
          name: item.extra ? item.extra.name : null,
          lat: item.extra ? item.extra.latitude : null,
          long: item.extra ? item.extra.longitude : null,
          address: item.extra ? item.extra.address : null,
        },
        voice: {
          filename:
            item.type === 'voice' || item.type === 'audio'
              ? item.file.filename
              : null,
          size:
            item.type === 'voice' || item.type === 'audio'
              ? (item.file.size / 1048576).toFixed(2)
              : null,
          url:
            item.type === 'voice' || item.type === 'audio'
              ? item.file.url
              : null,
        },
        email: {
          attachments,
          attachments_type:
            channel === 'email' && item.attachments ? item.type : null,
          cc: channel === 'email' ? item.cc : null,
          bcc: channel === 'email' ? item.bcc : null,
          reply_to: channel === 'email' ? item.reply_to : null,
        },
        story: {
          url: item.type === 'story' ? item.url : null,
        },
      },
      created_at: item.created_at,
      // type: item.type === 'text' || item.type === 'image' || item.type === 'document' ? 'message' : item.type,
      type: item.type === 'system' ? item.type : 'message',
      room_id: item.room_id,
      sender: {
        id: item.sender_id,
        type: item.sender_type,
        ...senderProfile,
        ...item.sender,
      },
      participant_id: item.participant_id,
      participant_type: item.participant_type,
      status: item.status,
      external_id: item.external_id,
      text: item.text ? item.text : null,
      url: item.url ? item.url : null,
      reply: item.reply,
      buttons: item.buttons,
      childs: item.childs || { data: [], meta: {} },
      read_only: isLoadMore,
    }
    if (channel === 'email') {
      messageItem.cc = item.cc
      messageItem.bcc = item.bcc
      messageItem.reply_to = item.reply_to
    }
    message.push(messageItem)
  })

  return message.reverse()
}

export const updateRoomPooling = (payload, roomLists) => {
  payload.forEach((room) => {
    const index = _.findIndex(roomLists, { id: room.id })
    if (index > -1) {
      roomLists.splice(index, 1, room)
    } else {
      roomLists.unshift(room)
    }
  })
  const sortedRoomLists = roomLists.sort((a, b) => {
    return new Date(b.last_activity_at) - new Date(a.last_activity_at)
  })
  return sortedRoomLists
}

export const getLatestRoomCursor = (roomLists) => {
  const firstRoom = roomLists[0]
  if (firstRoom && firstRoom.last_activity_at) {
    return moment(firstRoom.last_activity_at).valueOf()
  }
  return ''
}

export const compareConversations = (options) => {
  return new Promise((resolve) => {
    const p = new URLSearchParams(window.location.search)
    const currentRoom = p.get('room')

    const conversations = options.conversations
    const currentConv = options.currentConv
    const data = options.data
    const room = options.room

    // const currentConvId = currentConv.id || -1
    const lastConv = conversations.length
      ? conversations[conversations.length - 1]
      : {}

    /**
     * Prevent longpool updates for different room or last conversation id
     */
    if (currentRoom !== room || currentConv?.id !== lastConv?.id) {
      resolve(false)
      return
    }

    const latestConversations = _.sortBy(data, (c) => c.created_at)

    const mapConvIds = {}
    conversations.forEach((c) => {
      mapConvIds[c.id] = true
    })
    const latestConvSlice = latestConversations.filter((c) => {
      return !mapConvIds[c.id]
    })

    if (latestConvSlice.length) {
      resolve(latestConvSlice)
    } else {
      resolve(false)
    }
  })
}

/**
 * @param {Boolean} scrollIsEnd - The scroll is located at the end of the conversation on the bottom
 * @param {Boolean} lastMessageIsCustomer - Last message participant type is "customer"
 * @param {Boolean} lastMessageIsNotRead - Last message status is not "read"
 * @param {Boolean} unreadCountIsMoreThanZero - Room unread count is bigger than 0
 * @param {Boolean} userIsRoomParticipant - User is participant in the room
 * @param {Boolean} isNotReadingAllMessages - User still not reading all messages
 * @param {Boolean} userIsOnline - If user is an agent, it must online. Otherwise can skip this rule
 */
export const setMessageAsRead = (
  scrollIsEnd,
  lastMessageIsCustomer,
  lastMessageIsNotRead,
  unreadCountIsMoreThanZero,
  userIsRoomParticipant,
  isNotReadingAllMessages,
  userIsOnline
) => {
  /**
   * Mark message as read rule:
   * 1. Scroll located at the end of the conversation on the bottom
   * 2. Last message participant type is "customer"
   * 3. Last message status is not "read"
   * 4. Room unread count is bigger than 0
   * 5. User is participant in the room
   * 6. User still not reading all messages
   * 7. If user is an agent, it must online. Otherwise can skip this rule
   */
  if (
    scrollIsEnd &&
    lastMessageIsCustomer &&
    lastMessageIsNotRead &&
    unreadCountIsMoreThanZero &&
    userIsRoomParticipant &&
    isNotReadingAllMessages &&
    userIsOnline
  ) {
    return true
  } else {
    return false
  }
}
