const reporting = {
  general: {
    agents: {
      status: true,
    },
    speed: {
      status: true,
    },
    ratedAgents: {
      status: true,
    },
    inbox: {
      status: true,
    },
    mau: {
      status: true,
    },
    total_agent_supervisor: {
      status: true,
    },
    all_time_historical_mau: {
      status: true,
    },
    tag: {
      status: true,
    },
    conversation_log: {
      status: true,
    },
    new_room_hour: {
      status: true,
    },
    new_room_day: {
      status: true,
    },
    average_first_response: {
      status: true,
    },
    average_waiting_time: {
      status: true,
    },
    average_resolution_time: {
      status: true,
    },
  },
  agent_performance: {
    agent_online_status: {
      status: true,
    },
    conversation_status: {
      status: true,
    },
    speed: {
      status: true,
    },
    agent_performance_comparison: {
      status: true,
    },
    user_online_status: {
      status: true,
    },
    hand_over_conversation: {
      status: true,
    },
    on_going_conversation: {
      status: true,
    },
    top_rated_agents_csat: {
      status: true,
    },
    top_rated_agents_ces: {
      status: true,
    },
  },
  broadcast: {
    total_message: {
      status: true,
    },
    total_reply: {
      status: true,
    },
    broadcast_log: {
      status: true,
    },
    broadcast_data: {
      status: true,
    },
  },
  nps: {
    overallNps: {
      status: true,
    },
    npsBreakdown: {
      status: true,
    },
    npsResponses: {
      status: true,
    },
  },
  csat: {
    csatBreakdown: {
      status: true,
    },
    csatResponses: {
      status: true,
    },
  },
  ces: {
    cesBreakdown: {
      status: true,
    },
    cesResponses: {
      status: true,
    },
  },
  contact: {
    total: {
      status: true,
    },
    new_contact_overtime: {
      status: true,
    },
    new_contact: {
      status: true,
    },
    across_time_day: {
      status: true,
    },
    across_time_hour: {
      status: true,
    },
    new_trends_daily: {
      status: true,
    },
    new_trends_weekly: {
      status: true,
    },
    new_trends_monthly: {
      status: true,
    },
    new_trends_quarterly: {
      status: true,
    },
  },
}
export default reporting
