import moment from 'moment'

const onShortTotalData = (data) => {
  if (data >= 1000 && data < 1000000) return `${(data / 1000).toFixed(1)}k`
  else if (data >= 1000000 && data < 1000000000)
    return `${(data / 1000000).toFixed(1)}m`
  else if (data >= 1000000000) return `${(data / 1000000000).toFixed(1)}b`
  else return data
}

const onHandleResponsiveScreen = (width) => {
  let result = ''
  if (window.matchMedia(`(max-width: ${width}px)`).matches) {
    result = true
  } else {
    result = false
  }
  return result
}

const toCapitalize = (word) => {
  const arr = word.split(' ')
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  return arr.join(' ')
}

const validTokenCookie = () => {
  const cookieObj = new URLSearchParams(
    document.cookie.replaceAll('&', '%26').replaceAll('; ', '&')
  )
  return cookieObj.get('qontak._token.hub')
}

const onTruncateText = (text, n) => {
  if (!text) return ''
  return text.length > n ? text.slice(0, n) + '...' : text
}

const onDayAlias = (days) => {
  let result = ''
  if (days < 181) {
    result = `${days} days`
    return
  }

  const monthCalculated = Math.floor(days / 30)
  result = `${monthCalculated} ${monthCalculated > 1 ? 'months' : 'month'}`
  return result
}

const getDateFromDateRangeCalculation = (type, startDate, rangeDate) => {
  if (!type || !rangeDate) return moment(startDate).format('YYYY-MM-DD')
  const results = []
  if (type === 'next') {
    for (let i = 0; i <= rangeDate; i++) {
      results.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'))
    }
  }
  if (type === 'previous') {
    for (let i = rangeDate; i >= 0; i--) {
      results.push(moment(startDate).subtract(i, 'days').format('YYYY-MM-DD'))
    }
  }
  return results
}

/**
 * @description Store user logged in to locastorage
 * @param email - The value of the email to store logged in to
 * @returns reflected to localstorage
 */
const storeUserLoggedInLocalStorage = (email) => {
  const CHAT_LOGIN_COUNT = localStorage.getItem('CHAT_LOGIN_COUNT')
  const initData = [
    {
      email,
      count: 1,
    },
  ]

  if (CHAT_LOGIN_COUNT) {
    const anyData = JSON.parse(CHAT_LOGIN_COUNT).filter((data) => {
      return data.email === email
    })

    if (anyData.length > 0) {
      const datas = JSON.parse(CHAT_LOGIN_COUNT)
      datas.filter((log) => {
        if (log.email === email) {
          log.count = log.count + 1
        }
        return log
      })
      localStorage.setItem('CHAT_LOGIN_COUNT', JSON.stringify(datas))
    } else {
      localStorage.setItem(
        'CHAT_LOGIN_COUNT',
        JSON.stringify([...JSON.parse(CHAT_LOGIN_COUNT), { email, count: 1 }])
      )
    }
  } else {
    localStorage.setItem('CHAT_LOGIN_COUNT', JSON.stringify(initData))
  }
}

const minutesToHours = (mins) => {
  const minute = mins ? Number(mins) : 0
  const hours = Math.floor(minute / 60)
  const restMinutes = minute ? (minute > 59 ? minute % 60 : minute) : 0
  return {
    result: hours,
    rest: restMinutes,
  }
}

const minutesToDays = (mins) => {
  const minute = mins ? Number(mins) : 0
  const days = Math.floor(minute / 1440)
  const restMinutes = minute ? (minute > 1439 ? minute % 1440 : minute) : 0
  const restHours =
    restMinutes > 59 ? Math.floor(restMinutes / 60) : restMinutes
  return {
    result: days,
    rest: restHours,
  }
}

const hoursToMinutes = (hour) => {
  const hours = hour ? Number(hour) : 0
  return hours * 60
}

const daysToMinutes = (day) => {
  const days = day ? Number(day) : 0
  return days * 1440
}

const handleHideSubmenuButton = (submenuButton, flag) => {
  const targetHideSubmenuButton = []

  if (
    flag &&
    flag.settings &&
    !flag.settings.integration_with_shopee_chat_enabled
  )
    targetHideSubmenuButton.push('shopee')
  if (flag && flag.me && flag.me.settings && !flag.me.settings.custom_report)
    targetHideSubmenuButton.push('custom_report')

  targetHideSubmenuButton.forEach((item) => {
    delete submenuButton[item]
  })
  return submenuButton
}

const formatPriceIDR = (data) => {
  const IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  })
  return IDR.format(data)
}

const convertTimeIntoConversationFormat = (date) => {
  if (moment().format('DD-MM-YYYY') === moment(date).format('DD-MM-YYYY')) {
    return (date = 'Today, ' + moment(date).format('HH:mm'))
  } else if (
    moment().subtract(1, 'days').format('DD-MM-YYYY') ===
    moment(date).format('DD-MM-YYYY')
  ) {
    return (date = 'Yesterday, ' + moment(date).format('HH:mm'))
  } else if (
    moment().subtract(2, 'days').format('DD-MM-YYYY') ===
      moment(date).format('DD-MM-YYYY') ||
    moment().subtract(3, 'days').format('DD-MM-YYYY') ===
      moment(date).format('DD-MM-YYYY') ||
    moment().subtract(4, 'days').format('DD-MM-YYYY') ===
      moment(date).format('DD-MM-YYYY') ||
    moment().subtract(5, 'days').format('DD-MM-YYYY') ===
      moment(date).format('DD-MM-YYYY')
  ) {
    return (date =
      moment(date).format('dddd') + ', ' + moment(date).format('HH:mm'))
  } else {
    return (date =
      moment(date).format('DD/MM/YYYY') + ', ' + moment(date).format('HH:mm'))
  }
}

export {
  onShortTotalData,
  onHandleResponsiveScreen,
  toCapitalize,
  validTokenCookie,
  onTruncateText,
  onDayAlias,
  getDateFromDateRangeCalculation,
  storeUserLoggedInLocalStorage,
  minutesToHours,
  minutesToDays,
  hoursToMinutes,
  daysToMinutes,
  handleHideSubmenuButton,
  formatPriceIDR,
  convertTimeIntoConversationFormat,
}
